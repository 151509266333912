import React from 'react';
import Icon from '@ant-design/icons';

const SeedSvg = () => (
  <>
    <svg t="1696230447485" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4244" width="1em" height="1em"><path d="M511.4 36c0 0-115.2 133.8-113.4 261.8 0.2 15.8 2.2 31.6 5.6 47 47 51.6 86 115.2 116.4 190 27.8-62.4 60.2-120 100-170 7.6-25.6 12.2-52.2 11.8-78.6C630 158.2 511.4 36 511.4 36L511.4 36zM48.2 223c0.2 3.6 0.4 6 0.8 10.4 2 18.8 6 45.2 13.6 74.4 15.4 58.6 46 128.2 104.6 175.6 33 26.6 80.6 41.6 129.2 54.2s97.8 22.6 135.2 46.4c30 19 48.6 48.2 56.8 81.6 8.2 33.4 6.6 70.8-1.4 110.2-14.2 68.6-48.6 143.4-93.8 211.8L646 987.6c-45.6-68.8-80-144-93.8-212.8-8-39.4-9.2-77-0.8-110.4 8.4-33.4 27.4-62.6 57.6-81.6 37.6-23.6 87-33.4 135.6-45.8 48.6-12.4 96-27 128.6-53.2 95-76.8 114.6-211.6 118.8-260.4-123.2 3.8-213.8 42.2-284.8 104.4-74.8 65.6-128.2 158.2-170.6 264.8l-10.6 26.4-20-20-3-3-1.4-4.2C420.8 355 266.6 230.2 48.2 223z" p-id="4245" fill="#5995fd"></path></svg>
  </>
)

export const SeedIcon = (props) => <Icon component={SeedSvg} {...props} />;

const TwoDotSvg = () => (
  <>
    <svg t="1696253139742" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9676" width="1.2em" height="1.2em"><path d="M378.434783 589.913043c-20.034783 0-41.182609-8.904348-55.652174-23.373913-14.469565-14.469565-22.26087-34.504348-22.26087-54.53913 0-20.034783 8.904348-40.069565 22.26087-55.652174 14.469565-14.469565 34.504348-22.26087 55.652174-22.260869 20.034783 0 40.069565 8.904348 54.53913 22.260869 14.469565 14.469565 23.373913 34.504348 23.373913 55.652174 0 20.034783-8.904348 40.069565-23.373913 54.53913-14.469565 14.469565-34.504348 23.373913-54.53913 23.373913z m321.669565-23.373913c14.469565-14.469565 23.373913-34.504348 23.373913-54.53913 0-20.034783-8.904348-40.069565-23.373913-55.652174-14.469565-14.469565-34.504348-22.26087-54.539131-22.260869-20.034783 0-41.182609 8.904348-55.652174 22.260869-14.469565 14.469565-22.26087 34.504348-22.260869 55.652174 0 20.034783 8.904348 40.069565 22.260869 54.53913 14.469565 14.469565 34.504348 23.373913 55.652174 23.373913 20.034783 0 40.069565-8.904348 54.539131-23.373913z" p-id="9677" fill="#a7a7a7"></path></svg>
  </>
)

export const TwoDotIcon = (props) => <Icon component={TwoDotSvg} {...props} />;


const ThreeDotSvg = () => (
  <>
    <svg t="1696260763895" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4042" width="0.8em" height="0.8em"><path d="M162.8 515m-98.3 0a98.3 98.3 0 1 0 196.6 0 98.3 98.3 0 1 0-196.6 0Z" p-id="4043" fill="#a7a7a7"></path><path d="M511.9 515m-98.3 0a98.3 98.3 0 1 0 196.6 0 98.3 98.3 0 1 0-196.6 0Z" p-id="4044" fill="#a7a7a7"></path><path d="M762.8 515a98.3 98.3 0 1 0 196.6 0 98.3 98.3 0 1 0-196.6 0Z" p-id="4045" fill="#a7a7a7"></path></svg>
  </>
)

export const ThreeDotIcon = (props) => <Icon component={ThreeDotSvg} {...props} />;
